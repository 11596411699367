<template> 
        <div class="bread" :style="{background:bbg.bg}"  >
			<div class="breadMode"  :style="{color:bbg.color}" >
				Position：
				<div class="breadModeN"  v-if="pageTitle!='Home'" @click="Jump('/')">Home</div>
				<div class="line"  :style="{color:bbg.color}" v-if="pageTitle!='home'">/</div>
                <div class="breadModeN1"  :style="{color:bbg.color}" v-if="pagepath!=''" @click="Jump(pagepath1)">{{pagepath}}</div>
				<div class="line"  :style="{color:bbg.color}" v-if="pagepath!=''">/</div>
				<div class="breadModeN2"  :style="{color:bbg.color}" v-if="pageTitle!='home'">{{pageTitle}}</div>
			</div>
		</div>
</template>
<script>
export default {
  data() {
    return {
      pageTitle: "home",  //路由名称（大写）
      pagelists: [],

      pagepath: "",  //显示第一层
      pagepath1: "" //显示第二层
    };
  },
  methods: {
    init() {
      this.pageTitle = this.$route.meta.content.name;

     

      //home
      if (  this.pageTitle == "/" || this.pageTitle == "Home") {
        this.pagepath = "";
        this.pagepath1 = "";
      }

      //CNC PLASTIC
      if ( this.pageTitle == "CNC Plastic" || this.pageTitle == "CNC Machining Plastics" ) {
         
          this.pagepath = "CNC Plastic";
          this.pagepath1 = "";
      
      }
      //CNC METAL
      if ( this.pageTitle == "CNC Metal" || this.pageTitle == "CNC Machining Metals" ) {
         
          this.pagepath = "CNC Metal";
          this.pagepath1 = "";
      
      }
      //SURFACE FINISH
       if ( this.pageTitle == "SURFACE FINISH") {
          this.pagepath = "";
          this.pagepath1 = "";
      }
      //INDUSTRIES
      if ( this.pageTitle == "Aerospace & UAV" || this.pageTitle == "Automotive" || this.pageTitle == "Communications"  || this.pageTitle == "Consumer Products"  || this.pageTitle == "Industrial"  || this.pageTitle == "Medical"  || this.pageTitle == "Product Development"  || this.pageTitle == "Robotics & Automation"  ) {
          this.pagepath = "Industries";
          this.pagepath1 = "";
      }
      //COMPANY
      if ( this.pageTitle == "About us" || this.pageTitle == "Quality Assurance" ) {
          this.pagepath = "Company";
          this.pagepath1 = "";
      }
    },
    Jump(path) {
      this.$router.push({ path: path }).catch(err => err);
    }
  },
  mounted() {
    this.init();
  },
  created() {},
  props:{
    bbg:{
      bg:'#ffffff',
      color:'#000000'
    }
},
  watch: {},
  components: {}
};
</script>
<style scoped  lang="scss">
.bread {
  width: 100%;
  // height: 60px;
  line-height: 30px;
  background: #fff;
  font-size: 20px;
  // padding-bottom: 20px;
  padding: 20px 0;
 

  .breadMode {
    // width: 1280px;
    margin: auto;
    display: flex;
    color: #000;
    align-items: center;
    font-size: 14px;
    .breadModeN {
      cursor: pointer;
      color: #999999;
    }
    .breadModeN:hover {
      color: #da251d;
    }
    .breadModeN2 {
      //  cursor: pointer;
      line-height: 15px;
    }
    .breadModeN1 {
      cursor: pointer;
    }
    .breadModeN2:hover {
      //    color: #DA251D;
    }

    .line {
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 1350px) {
  //最小  min-width="330px"
  .breadMode {
    width: 90%;
  }
}

@media screen and (min-width: 1350px) {
  //最大限制   width="1280px"
  .breadMode {
    width: 1200px;
  }
}
</style>