<template> 
	<div id="header">
	<div class="headBox" :style="{background:hbg.bg}">
		<div class="w">
			<div class="mylogo" @click="jump('/')">
					<img src="../assets/img/Tib2T8siZK.png" alt="">
			</div>
	<div class="rigth">
			<div class="rigthbg">
 
			<div class="home"  :class="currentPage == 'Home'?'active2':''" @click="jump('/')">HOME	</div>
			<div class="order" :class="{'active2':currentPage == 'cnc-plastic'||currentPage == 'cnc-machining-plastic'}">CNC PLASTIC
				<div class="ServicesList">
				<p><router-link to="/cnc-plastic"  :class="{'active1':currentPage == 'cnc-plastic'}" >CNC Plastic Machining</router-link></p>
                <p><router-link   to="/cnc-machining-plastic" :class="{'active1':currentPage == 'cnc-machining-plastic'}">CNC Plastic Material</router-link></p>
				</div>
			</div>
			<div class="order"  :class="{'active2':currentPage == 'cnc-metal'||currentPage == 'cnc-machining-metal'}">CNC METAL 
				<div class="ServicesList">
					<p><router-link :class="{'active1':currentPage == 'cnc-metal'}"  to="/cnc-metal">CNC Metal Machining</router-link></p>
                    <p><router-link :class="{'active1':currentPage == 'cnc-machining-metal'}"  to="/cnc-machining-metal">CNC Metal Material</router-link></p>
				</div>
			</div>
		<div class="home"  :class="currentPage == 'surface-finish'?'active2':''"  @click="jump('/surface-finish')">  SURFACE FINISH
			</div>
            <div class="order" :class="{'active2':currentPage == 'aerospace-uav'||currentPage == 'automotive'||currentPage == 'communications'||currentPage == 'consumer-products'||currentPage == 'industrial'||currentPage == 'medical'||currentPage == 'product-development'||currentPage == 'robotics-automation'}">INDUSTRIES
				<div class="ServicesList">
		<p><router-link  :class="{'active1':currentPage == 'aerospace-uav'}"  automotive to="/aerospace-uav">Aerospace & UAV</router-link></p>
                    <p><router-link :class="{'active1':currentPage == 'automotive'}"  to="/automotive">Automotive</router-link></p>
                      <p><router-link :class="{'active1':currentPage == 'robotics-automation'}"  to="/robotics-automation">Robotics & Automation</router-link></p>
                    <p><router-link :class="{'active1':currentPage == 'communications'}"  to="/communications">Communications</router-link></p>
                    <p><router-link :class="{'active1':currentPage == 'consumer-products'}"  to="/consumer-products">Consumer Products</router-link></p>
                    <p><router-link :class="{'active1':currentPage == 'industrial'}"  to="/industrial">Industrial</router-link></p>
                    <p><router-link :class="{'active1':currentPage == 'medical'}"  to="/medical">Medical</router-link></p>
                    <p><router-link :class="{'active1':currentPage == 'product-development'}"  to="/product-development">Product Development</router-link></p>
                  
				</div>
			</div>
            <div class="order" :class="{'active2':currentPage == 'about-us'||currentPage == 'quality-assurance'||currentPage == 'Blog'||currentPage == 'contact-us'}">COMPANY
 
				<div class="ServicesList">
					<p><router-link  :class="{'active1':currentPage == 'about-us'}"   to="/about-us">About Us</router-link></p>
				<p><router-link  :class="{'active1':currentPage == 'contact-us'}"   to="/contact-us">Contact Us</router-link></p>

          
                    <p><router-link  :class="{'active1':currentPage == 'quality-assurance'}"   to="/quality-assurance">Quality Assurance</router-link></p>
                    <p class="Blog"  :class="{'active1':currentPage == 'Blog'}"   @click="jump4(Blog)">Blog</p>
				</div>
			</div>
      <div class="home"  :class="currentPage == 'Factory Tour'?'active2':''" @click="openUrl('https://www.hlhcnc.com/vr/index.html')">Factory Tour	</div>
			</div>
        </div>
		<div class="rigth2" >
			<img v-show="!isNav" @click="isNav = !isNav" class="headIcon " src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/ixTzKSf7dK.png" alt="">
			<img v-show="isNav" @click="isNav = !isNav" class="headIcon" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/MF4skmnJGT.png" alt="">
		</div>
		</div>
		<div   class="navBox" v-show="isNav">
			<div class="navBoxbg">
			<div class="navItem">
				<p  :style="{color:(currentPage=='Home'?colorbg:'#ccc')}"  @click="jump('/')">Home</p>
			</div>
			<div class="navItem">
				<p :class="{'active':Resources1}" @click="ResourcesFun1">
					<span :class="{'active1':currentPage == 'cnc-plastic'||currentPage == 'cnc-machining-plastic'}">CNC PLASTIC</span>
					<img v-show="!Resources1" class="rightIcon1" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/2jNSHEjJA4.png" alt="">
					<img v-show="Resources1" class="rightIcon2" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/HbQZ66BGC5.png" alt="">
				</p>
				<div class="subNav" v-show="Resources1">
					<p :style="{color:(currentPage=='cnc-plastic'?colorbg:'#ccc')}" @click="jump('/cnc-plastic')">CNC Plastic Machining</p>
          <p :style="{color:(currentPage=='cnc-machining-plastic'?colorbg:'#ccc')}" @click="jump('/cnc-machining-plastic')">CNC Plastic Material</p>
          
				</div>
			</div>
    <div class="navItem">
				<p :class="{'active':Resources2}" @click="ResourcesFun2">
					<span :class="{'active1':currentPage == 'cnc-metal'||currentPage == 'cnc-machining-metal'}">CNC METAL</span>
					<img v-show="!Resources2" class="rightIcon1" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/2jNSHEjJA4.png" alt="">
					<img v-show="Resources2" class="rightIcon2" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/HbQZ66BGC5.png" alt="">
				</p>
				<div class="subNav" v-show="Resources2">
					<p :style="{color:(currentPage=='cnc-metal'?colorbg:'#ccc')}" @click="jump('/cnc-metal')">CNC Metal Machining </p>
          <p :style="{color:(currentPage=='cnc-machining-metal'?colorbg:'#ccc')}" @click="jump('/cnc-machining-metal')">CNC Metal Material </p>
          
				</div>
			</div>

      <div class="navItem">
				<p  :style="{color:(currentPage=='surface-finish'?colorbg:'#cccccc')}"  @click="jump('/surface-finish')">SURFACE FINISH</p>
			</div>
      <div class="navItem">
				<p :class="{'active':Resources4}" @click="ResourcesFun4">
					<span :class="{'active1':currentPage == 'aerospace-uav'||currentPage == 'automotive'||currentPage == 'communications'||currentPage == 'consumer-products'||currentPage == 'industrial'||currentPage == 'medical'||currentPage == 'product-development'||currentPage == 'robotics-automation'}">INDUSTRIES</span>
					<img v-show="!Resources4" class="rightIcon1" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/2jNSHEjJA4.png" alt="">
					<img v-show="Resources4" class="rightIcon2" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/HbQZ66BGC5.png" alt="">
				</p>
				<div class="subNav" v-show="Resources4">
					<p :style="{color:(currentPage=='aerospace-uav'?colorbg:'#ccc')}" @click="jump('/aerospace-uav')">Aerospace & UAV </p>
          <p :style="{color:(currentPage=='automotive'?colorbg:'#ccc')}" @click="jump('/automotive')">Automotive</p>
          <p :style="{color:(currentPage=='robotics-automation'?colorbg:'#ccc')}" @click="jump('/robotics-automation')">Robotics & Automation</p>
          <p :style="{color:(currentPage=='communications'?colorbg:'#ccc')}" @click="jump('/communications')">Communications</p>
          <p :style="{color:(currentPage=='consumer-products'?colorbg:'#ccc')}" @click="jump('/consumer-products')">Consumer Products</p>
          <p :style="{color:(currentPage=='industrial'?colorbg:'#ccc')}" @click="jump('/industrial')">Industrial</p>
          <p :style="{color:(currentPage=='medical'?colorbg:'#ccc')}" @click="jump('/medical')">Medical</p>
          <p :style="{color:(currentPage=='product-development'?colorbg:'#ccc')}" @click="jump('/product-development')">Product Development</p>
          

          
				</div>
			</div>
      <div class="navItem">
				<p :class="{'active':Resources5}" @click="ResourcesFun5">
					<span :class="{'active1':currentPage == 'about-us'||currentPage == 'quality-assurance'}">COMPANY</span>
					<img v-show="!Resources5" class="rightIcon1" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/2jNSHEjJA4.png" alt="">
					<img v-show="Resources5" class="rightIcon2" src="https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0908/HbQZ66BGC5.png" alt="">
				</p>
				<div class="subNav" v-show="Resources5">
					<p :style="{color:(currentPage=='about-us'?colorbg:'#ccc')}" @click="jump('/about-us')">About Us</p>
          <p :style="{color:(currentPage=='contact-us'?colorbg:'#ccc')}" @click="jump('/contact-us')">Contact Us</p>
          <p :style="{color:(currentPage=='quality-assurance'?colorbg:'#ccc')}" @click="jump('/quality-assurance')">Quality Assurance</p>
          <p :style="{color:(currentPage=='Blog'?colorbg:'#ccc')}" @click="jump4(Blog)">Blog</p>
				</div>
			</div>

       <div class="FactoryBg"> <div class="Factory" @click="openUrl('https://www.hlhcnc.com/vr/index.html')">Factory Tour</div></div>
      
 
			</div>
		</div>
	</div>
</div>
</template>
 
 
<script>
export default {
  name: "myHead",

  data() {
    return {
      Blog: "https://blog.hlhcnc.com/",
      lb: "",
      colorbg: "#da251d",
      isNav: false,
      subHead: false,
      Prototypes: false,
      Resources1: false,
      Resources2: false,
      Resources3: false,
      Resources4: false,
      Resources5: false,

      windowHeight: document.documentElement.clientHeight + "px",
      isHeadFixed: false,
      currentPage: "",
      pageTitle: "home"
    };
  },

  props: {
    hbg: {}
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.currentPage = to.name;

      this.pageTitle = to.meta.content.name;

      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    // "$store.state.lb": function() {
    //   this.lb = this.$store.state.lb;
    // }
  },
  mounted() {
    this.currentPage = this.$route.name;
    this.pageTitle = this.$route.meta.content.name;
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    console.log("this.currentPage", this.currentPage);
  },
  methods: {
    openUrl(url){
  window.open(url)
},
    jump(path) {
      this.isNav = false;
      this.$router.push({ path: path }).catch(err => err);
    },
    jump4(path) {
      this.isNav = false;
      window.open(path);
    },
    jump3(path, lb) {
      this.isNav = false;
      this.$router.push({ path: path }).catch(err => err);
      this.$store.dispatch("setLb", lb);
    },

    isTou() {
      this.isNav = false;
      this.$store.dispatch("setDialogVisible", true);
    },

    goAnchor(id) {
      var anchor = document.getElementById(id);

      var anchorSum = anchor.offsetTop - 100;
      document.body.scrollTop = anchorSum;
      document.documentElement.scrollTop = anchorSum;
      window.pageYOffset = anchorSum;
    },

    ResourcesFun1() {
      this.Resources1 = !this.Resources1;
      this.Resources2 = false;
      this.Resources3 = false;
      this.Resources4 = false;
      this.Resources5 = false;
    },
    ResourcesFun2() {
      this.Resources1 = false;
      this.Resources2 = !this.Resources2;
      this.Resources3 = false;
      this.Resources4 = false;
      this.Resources5 = false;
    },
    ResourcesFun3() {
      this.Resources1 = false;
      this.Resources2 = false;
      this.Resources3 = !this.Resources3;
      this.Resources4 = false;
      this.Resources5 = false;
    },
    ResourcesFun4() {
      this.Resources1 = false;
      this.Resources2 = false;
      this.Resources3 = false;
      this.Resources4 = !this.Resources4;
      this.Resources5 = false;
    },
    ResourcesFun5() {
      this.Resources1 = false;
      this.Resources2 = false;
      this.Resources3 = false;
      this.Resources4 = false;
      this.Resources5 = !this.Resources5;
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">
#header {
  // margin-bottom: 50px;
  
  .headBox {
    width: 100%;
    background-color: white;

    display: flex;
    // position: fixed;
    z-index: 2033;
    top: 0;

 .FactoryBg{
   display: flex;
   justify-content: center;
   margin-top:100px 
 }
 .Factory{
   width: 90%;
   margin: auto;
   background: rgb(218, 37, 29);
   text-align: center;
   
 }
    .active1 {
      color: #da251d !important;
    }
    .active2 {
      background: #da251d !important;
      color: #ffffff;
    }
    .activecolor2 {
      color: #ffffff !important;
    }

    .w {
      // width: 1200px;
      align-items: center;
      display: flex;
      justify-content: center;

      margin: auto;

      box-shadow: 0px 0px 10px #dbdbdb;
      .rigth {
        width: 100%;
      }

      > div {
        color: black;
        text-align: center;

        font-size: 14px;
        position: relative;

        .border {
          display: inline-block;
          width: 50px;
          height: 2px;
          background-color: transparent;
          position: absolute;
          bottom: 0;
          left: 25px;
        }

        &:hover {
          cursor: pointer;
          > a {
            color: #da251d;
          }
          > span {
            color: #da251d;
          }
        }
        > a {
          color: #fff;
          display: inline-block;
          height: 100%;
        }
        .active {
          color: #da251d;
        }
        > span {
          display: inline-block;
          height: 100%;
        }
      }
      .mylogo {
        img {
          width: 200px;
          // height: 36px;
          vertical-align: middle;
        }
      }

      .rigthbg {
        display: flex;
        width: 100%;

        justify-content: center;
        .home {
          background: #ffffff;
          width: 16.7%;
          text-align: center;
          position: relative;
          border: 2px solid rgba(219, 219, 219, 1);
          border-right: 0px;
          font-weight: bold;
          a {
            color: #000;
          }
        }
        .home:hover {
          background: #da251d;
          color: #ffffff;
        }
        .home:hover a {
          color: white;
        }

        .order {
          width: 16.7%;
          text-align: center;
          position: relative;
          background: #ffffff;

          border: 2px solid rgba(219, 219, 219, 1);
          border-right: 0px;
          font-weight: bold;

          &:hover .ServicesList {
            display: block;
          }
          .ServicesList {
            width: 100%;
            min-width: 230px;

            // margin-left:-5px

            // left: -70px;
          }
        }
        .order:hover {
          background: #da251d;
          color: white;
          transition: 0.15s;
        }
        .order:last-child {
          border-right: 2px solid rgba(219, 219, 219, 1);
        }
      }

      .logo {
        margin: 0 50px 0 0;
        padding: 0;
        width: auto;
        &:hover {
          background-color: transparent;
          border-color: transparent;
          cursor: auto;
        }
        img {
          vertical-align: middle;
        }
      }
      .Quote {
        &:hover div {
          color: #fff;
        }
        div {
          width: 198px;
          display: inline-block;
          background: #da251d;
          vertical-align: middle;
          line-height: 45px;
          text-align: center;
          font-size: 16px;
          margin-left: 81px;
          color: #fff;
        }
      }
      .ServicesList {
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
        position: absolute;
        left: 0;
        top: 60px;
        margin-top: 0px;
        padding: 20px 10px 10px 10px;
        display: none;
        z-index: 9;
        > p {
          line-height: normal;
          margin-bottom: 10px;
          text-align: left;
          margin-left: 15px;
          > a {
            display: inline-block;
            width: 100%;
            height: 30px;
            line-height: 30px;
            border-radius: 4px;
            // padding-left: 15px;
            font-size: 15px;
            color: #333;
            &:hover {
              color: #da251d;
            }
          }
        }
        .subListBox {
          position: relative;
          height: 30px;
          margin-bottom: 10px;
          > a {
            display: inline-block;
            width: 100%;
            height: 30px;
            line-height: 30px;
            border-radius: 4px;
            padding-left: 20px;
            color: #fff;
            text-align: left;
            left: 0;
            position: absolute;
            &:hover {
              background-color: #4641d0;
            }
          }
        }
      }
    }
    .bread {
      width: 100%;
      // height: 60px;
      line-height: 30px;
      background: #fff;
      font-size: 20px;
      padding-bottom: 20px;
      .breadMode {
        width: 1200px;
        margin: auto;
        display: flex;
        color: #000;
        align-items: center;
        font-size: 14px;
        .breadModeN {
          cursor: pointer;
          color: #999999;
        }
        .breadModeN:hover {
          color: #da251d;
        }
        .breadModeN2 {
          cursor: pointer;
        }
        .breadModeN2:hover {
          color: #da251d;
        }

        .line {
          margin: 0 10px;
        }
      }
    }
  }
}
.headIcon {
  margin-top: -15px;
  height: 100%;
  width: 100%;
}

.Blog {
  color: #000000;
  // padding-left:15px
}
.navBox {
  // height: 11rem;
  transition: 0.5s;

  background-color: #222222;
  height: 100vh;

  color: #fff;
  padding-bottom: 0.8rem;
  position: absolute;
  z-index: 111111;
  .navBoxbg {
    // height: 1000px;
    padding: 10px 0;
  }

  .navItem {
    border-bottom: 1px solid #666666;
    font-size: 17px;

    > p {
      width: 100%;
      line-height: 48px;
      display: block;
      align-items: center;

      .van-icon {
        float: right;
        margin-top: 0.45rem;
      }
      a {
        color: #fff;
        display: block;
        width: 100%;
        height: 100%;
      }
      .rightIcon1 {
        height: 0.8rem;
        float: right;
        margin-top: 15px;
      }
      .rightIcon2 {
        width: 0.8rem;
        float: right;
        margin-top: 15px;
      }
    }
    > .active {
      span {
        color: #da251d;
      }
      .van-icon {
        color: #da251d;
      }
    }
    .subNav {
      padding-left: 0.24rem;
      padding-bottom: 0.25rem;

      > p,
      > div {
        line-height: 48px;
        font-size: 17px;
        width: 100%;
        .van-icon {
          float: right;
          margin-top: 0.2rem;
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          color: #ccc;
        }
        .caseBox {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          div {
            display: inline-block;
            width: 49%;

            background-color: #333;
            margin-bottom: 2%;
            color: #999;
            padding-left: 0.3rem;

            font-size: 12px;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
        .rightIcon1 {
          height: 0.8rem;
          float: right;
          margin-top: 15px;
        }
        .rightIcon2 {
          width: 0.8rem;
          float: right;
          margin-top: 15px;
        }
        > .active {
          color: #da251d;
        }
      }
    }
  }
}
.headFixed {
  width: 100%;
  position: fixed !important;
  top: 0;
  z-index: 99;
}

.rigth2Img {
}
@media screen and (max-width: 760px) {
  .navItem {
    width: 90%;
    margin: auto;
  }
  #header {
    // margin-bottom: 50px;
  }
  .w {
    min-width: 330px;
    width: 90%;

    margin: 0 15px;
    box-shadow: none !important;
    justify-content: space-between !important;
  }
  .headBox {
    height: 50px;
    line-height: 50px;
    transition: 0.5s;
    background: #000000 !important;
    position: fixed;
    top: 0;
  }
  .rigth {
    display: none;
    width: 100%;
  }
  .navBox {
    display: inline;
    min-width: 330px;
    width: 100%;

    position: absolute;
    top: 50px;
  }
  .rigth2 {
    display: initial;
    width: 15px;
    height: 15px;
    line-height: 25px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .mylogo {
    display: block;
  }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {
  .ServicesList {
    // margin-left:-2vw
  }
  .navItem {
    width: 90%;
    margin: auto;
  }
  #header {
    // margin-bottom: 50px;
  }
  .w {
    min-width: 330px;
    width: 90%;

    margin: 0 15px;
    box-shadow: none !important;
    justify-content: space-between !important;
  }
  .headBox {
    height: 50px;
    line-height: 50px;
    transition: 0.5s;
    background: #000000 !important;
    position: fixed;
    top: 0;
  }
  .rigth {
    display: none;
    width: 100%;
  }
  .navBox {
    display: inline;
    min-width: 330px;
    width: 100%;

    position: absolute;
    top: 50px;
  }
  .rigth2 {
    display: initial;
    width: 15px;
    height: 15px;
    line-height: 25px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .mylogo {
    display: block;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1350px) {
  .ServicesList {
    margin-left: -3.5vw;
  }
  .navItem {
    width: 90%;
    margin: auto;
  }
  #header {
    // margin-bottom: 50px;
  }
  .w {
    min-width: 330px;
    width: 90%;
    margin: 0 15px;
  }
  .headBox {
    line-height: 64px;
    transition: 0.5s;
  }
  .Resovrces {
    display: none;
  }
  .home {
    // display: none;
  }
  .rigth {
    display: flex;
    justify-content: space-between;
  }
  .navBox {
    display: none;
    min-width: 330px;
    width: 100%;

    position: absolute;
    top: 50px;
  }
  .rigth2 {
    display: none;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .mylogo {
    display: none;
  }
}
//pc
@media screen and (min-width: 1350px) {
  .ServicesList {
    margin-left: -30px;
  }
  .mylogo {
    display: none;
  }
  .navBox {
    display: none;
  }
  #header {
    // margin-bottom: 100px;
  }
  .w {
    width: 1200px;
    margin: 0 15px;
  }

  .headBox {
    line-height: 64px;
    transition: 0.5s;
  }
  .rigth {
    display: flex;

    justify-content: space-between;
  }
  .rigth2 {
    display: none;
  }
}
</style>
